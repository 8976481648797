export enum multisigContractFunctionNames {
  'sign' = 'sign',
  'unsign' = 'unsign',
  'discardAction' = 'discardAction',
  'performAction' = 'performAction',
  'proposeChangeQuorum' = 'proposeChangeQuorum',
  'proposeAddBoardMember' = 'proposeAddBoardMember',
  'getAllBoardMembers' = 'getAllBoardMembers',
  'proposeAddProposer' = 'proposeAddProposer',
  'proposeRemoveUser' = 'proposeRemoveUser',
  'proposeTransferExecute' = 'proposeTransferExecute',
  'proposeAsyncCall' = 'proposeAsyncCall',
  'proposeSCDeployFromSource' = 'proposeSCDeployFromSource',
  'proposeSCUpgradeFromSource' = 'proposeSCUpgradeFromSource',
  'issue' = 'issue',
  'ESDTTransfer' = 'ESDTTransfer',
  'ESDTNFTTransfer' = 'ESDTNFTTransfer',
  'getPendingActionFullInfo' = 'getPendingActionFullInfo',
  'getNumBoardMembers' = 'getNumBoardMembers',
  'getNumProposers' = 'getNumProposers',
  'getQuorum' = 'getQuorum',
  'getActionLastIndex' = 'getActionLastIndex',
  'getActionData' = 'getActionData',
  'userRole' = 'userRole',
  'getAllProposers' = 'getAllProposers',
  'getActionSigners' = 'getActionSigners',
  'getActionValidSignerCount' = 'getActionValidSignerCount',
  'getActionSignerCount' = 'getActionSignerCount',
  'quorumReached' = 'quorumReached',
  'signed' = 'signed',
  'registerMultisigContract' = 'registerMultisigContract',
  'registerMultisigName' = 'registerMultisigName',
  'unregisterMultisigContract' = 'unregisterMultisigContract',
  'getMultisigContracts' = 'getMultisigContracts',
  'getMultisigContractName' = 'getMultisigContractName'
}
