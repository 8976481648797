export const germanTranslations = {
  'Your Multisig Wallets': 'Ihre Multisig-Brieftaschen',
  'Deploy Multisig': 'Multisignatur bereitstellen',
  'Add Existing Multisig': 'Existierende Multisignatur hinzufügen',
  Enter: 'Betreten',
  Unregister: 'Abmelden',
  'Wallet address': 'Brieftaschenadresse',
  Close: 'Schließen',
  'Multisig Deployment': 'Multisignatur-Bereitstellung',
  Deploy: 'Bereitstellen',
  'Register Name': 'Name registrieren',
  Attach: 'Anfügen',
  Name: 'Name',
  'Board Members': 'Vorstandsmitglieder',
  'Total Members': 'Gesamtmitgliederzahl',
  Proposers: 'Antragsteller',
  'Quorum Size': 'Quorumgröße',
  'User Role': 'Benutzer-Rolle',
  Proposals: 'Vorschläge',
  Propose: 'Vorschlagen',
  'Multisig Address': 'Multisignaturadresse',
  'Manage Multisigs': 'Multisignaturen verwalten',
  Proposer: 'Antragsteller',
  'Proposer / Signer': 'Antragsteller / Unterzeichner',
  'No rights': 'Keine Rechte',
  Unknown: 'Unbekannt',
  Sign: 'Signieren',
  Unsign: 'Unsignieren',
  'Perform Action': 'Aktion ausführen',
  'Discard Action': 'Aktion verwerfen',
  'Add Board Member': 'Vorstandsmitglied hinzufügen',
  'Add Proposer': 'Antragsteller hinzufügen',
  'Change Quorum': 'Quorum ändern',
  'New Quorum Size': 'Neue Quorumgröße',
  'Issue Token': 'Token ausgeben',
  'Send Token': 'Token senden',
  'Send NFT': 'NFT senden',
  'Remove User': 'Benutzer entfernen',
  'Send eGLD': 'eGLD senden',
  'Smart Contract Call': 'Smart Contract Aufruf',
  Identifier: 'Kennzeichen',
  Amount: 'Summe',
  Decimals: 'Dezimalstellen',
  Select: 'Auswählen',
  Address: 'Adresse',
  Data: 'Daten',
  'Mint Amount': 'Münzprägungs-betrag',
  'Can Freeze': 'Einfrierbar',
  'Can Wipe': 'Awischbar',
  'Can Pause': 'Pausierbar',
  'Can Mint': 'Prägbar',
  'My Total Stake': 'Mein gesamter Stake',
  'Can Burn': 'Verbrennbar',
  'Can Change Owner': 'Besitzer wechselbar',
  'Can Upgrade': 'Nachrüstbar',
  'Add Multisig': 'Multisignatur hinzufügen',
  Add: 'Hinzufügen',
  Multisig: 'Multisignatur',
  'Multisig Manager': 'Multisignaturmanager',
  'Multisig Details': 'Multisignaturdetails',
  'Confirm Perform Action': 'Bestätigung Aktion ausführen',
  'Confirm Discard Action': 'Bestätigung Aktion verwerfen',
  Confirm: 'Bestätigen',
  Cancel: 'Abbrechen',
  'Access wallet': 'Brieftasche öffnen',
  'No Multisig Wallet Yet': 'Sie haben noch keine Multisig-Brieftasche',
  'Welcome to our platform!': 'Willkommen zu unserer Plattform!',
  Balance: 'Saldo',
  Transactions: 'Transaktionen',
  'No NFTs to show': 'Keine NFTs verfügbar',
  'Loading actions': 'Aktionen werden geladen',
  'An error occured while fetching actions':
    'Beim Laden der Aktionen ist ein Fehler aufgetreten',
  // eslint-disable-next-line comma-dangle
  'Actions per page': 'Aktionen pro Seite',
  'No transactions found for this period':
    'Keine Transaktionen für diesen Zeitraum gefunden',
  Available: 'Verfügbar',
  'Insufficient funds': 'Unzureichender Kontostand',
  'Sending EGLD needs different transaction parameters. This will change the proposal type to "Send EGLD" and will remove the amount from the proposal. Are you sure you want to do this?':
    'Das Senden von EGLD benötigt andere Transaktionsparameter. Dafür wird der Vorschlagstyp auf "Send EGLD" geändert und die Summe vom Vorschlag entfernt. Möchten Sie das wirklich tun?',
  'Are you sure you want to change the proposal type to "Send EGLD" ?':
    'Möchten Sie wirklich den Vorschlagstyp auf "Send EGLD" ändern?',
  'There is not enough money in the organization for this transaction':
    'Es gibt nicht genug Geld in der Organisation für diese Transaktion',
};
