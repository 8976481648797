export enum QueryKeys {
  ALL_ORGANIZATION_NFTS = 'ALL_ORGANIZATION_NFTS',
  ALL_TRANSACTIONS_WITH_LOGS_ENABLED = 'ALL_TRANSACTIONS_WITH_LOGS_ENABLED',
  ALL_PENDING_ACTIONS = 'ALL_PENDING_ACTIONS',
  FETCHED_DELEGATIONS = 'FETCHED_DELEGATIONS',
  FETCHED_PROVIDERS = 'FETCHED_PROVIDERS',
  FETCHED_PROVIDER_IDENTITIES = 'FETCHED_PROVIDER_IDENTITIES',
  TOKEN_PRICES = 'TOKEN_PRICES',
  ADDRESS_ESDT_TOKENS = 'ADDRESS_ESDT_TOKENS',
  ADDRESS_EGLD_TOKENS = 'ADDRESS_EGLD_TOKENS',
  FETCHED_CONVERSION = 'FETCHED_CONVERSION',
  NFT_COUNT = 'NFT_COUNT',
  QUORUM_COUNT = 'QUORUM_COUNT',
  ATTACHED_CONTRACTS = 'ATTACHED_CONTRACTS'
}
